<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Reporte Márgenes Estimados</strong>
          </CCardHeader>
          <CCardBody>

          <b-form id="Form" autocomplete="off" @submit.prevent="Validate">
            <b-row class="row justify-content-md-center">

                <b-col sm="12" md="3">
                  <b-form-group label="Establecimiento">
                    <b-form-select v-model="report.id_establishment" :options="establishment"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col sm="12" md="2">
                  <b-form-group label="Hasta :">
                    <b-form-input class="text-center" type="date"   v-model="report.to"></b-form-input>
                    <small v-if="errors.to" class="form-text text-danger" >Seleccione una fecha</small>
                  </b-form-group>
                </b-col>

                <b-col sm="12" md="4">
                  <b-form-group label="Buscar :">
                    <b-form-input v-model="report.search"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col sm="12" md="1">
                  <b-form-group label=".">
                    <b-button class="form-control" type="submit" variant="primary"><i class="fas fa-search"></i></b-button>
                  </b-form-group>
                </b-col>

                <b-col sm="12" md="1">
                  <b-form-group label=".">
                    <b-button class="form-control" type="button" variant="success" @click="ExportExcel"><i class="fas fa-file-excel"></i></b-button>
                  </b-form-group>
                </b-col>

                

            </b-row>
          </b-form>


            <div class="table-responsive mt-3">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th rowspan="2" class="text-center">#</th>
                    <th rowspan="2" class="text-center">Código</th>
                    <th rowspan="2" class="text-center">Nombre</th>
                    <th colspan="3" v-for="(item, it) in data_establishments" :key="it" class="text-center">{{item}}</th>                
                  </tr>
                  <tr>
                     <th v-for="(item, it) in data_subheadear" :key="it" class="text-center">{{item}}</th>
                  
                  </tr>
                </thead>
                <tbody v-for="(item, it) in data_table" :key="it">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.code }}</td>
                    <td class="text-left"> {{ item.name + (item.presentation.length == 0 ? "": " - "+item.presentation) }}</td>
                    <td v-for="(stock, it1) in item.data_stock" :key="it1" class="text-right">
                          {{stock}}
                    </td>
                  
                  </tr>
                </tbody>
              </table>
            </div>



            <b-row class="mt-4">
              <b-col md="8">
                <b-pagination
                  v-model="currentPage"
                  v-on:input="Validate"
                  :total-rows="rows"
                  :per-page="perPage"
                  align="center"
                ></b-pagination>
              </b-col>
              <b-col md="4 text-center">
                <p>Pagina Actual: {{ currentPage }}</p>
              </b-col>
            </b-row>
  
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <LoadingComponent :is-visible="isLoading"/>
  </div>
</template>

<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import CodeToName from "@/assets/js/CodeToName";
var moment = require("moment");
import LoadingComponent from './../pages/Loading'

export default {
  name: "UsuarioList",
  components:{
    vSelect,
    LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'ReportSale',
      role:1,
      perPage: 15,
      currentPage: 1,
      rows: 0,
      search: "",
      data_table: [],
      data_establishments :[],
      data_subheadear:[],
      report:{
        id_establishment: 'all',
        search:'',
        to:moment(new Date()).local().format("YYYY-MM-DD"),
        page:1,
      },
      establishment:[],
      errors:{
        to:false,
        from:false,
      }
    };
  },
  mounted() {
    this.ListEstablishment();
  },
  methods: {
    ListEstablishment,
    Validate,
    Report,
    ExportExcel,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    id_establishment: function () {
      let id_establishment = window.localStorage.getItem("id_establishment");
      id_establishment = JSON.parse(je.decrypt(id_establishment));
      return id_establishment;
    },
  },
};

function ExportExcel() {  
  let me = this;
  me.report.search = me.report.search.length == 0 ? 'all':me.report.search;
  let url = me.url_base + "excel-report-estimated-margins/"+me.report.id_establishment+"/"+me.report.to+"/"+me.report.search;

  
  window.open(url,'_blank');
}

function ListEstablishment() {

  let me = this;
  let url = this.url_base + "active-establishments";
  axios({
    method: "GET",
    url: url,
    headers: { token: me.token, module: me.module, role: me.role,},
  })
    .then(function (response) {
      me.establishment = [{value:'all',text:'-- Todos --'}];
      if (response.data.status == 200) {
        for (let index = 0; index < response.data.result.length; index++) {
          const element = response.data.result[index];
          me.establishment.push({value: element.id_establishment, text: element.name});
        }
      } else {
        Swal.fire({ icon: 'error', text: 'A Ocurrido un error', timer: 2000,})
      }
    })
}


function Validate() {
  
  // this.errors.id_establishment = this.kardex.id_establishment.length == 0 ? true : false;
  // if (this.errors.id_establishment == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  this.Report();
}


function Report() {
  let me = this
  let data = me.report;
  let url = this.url_base + "report/estimated-margins";
  me.report.page = me.currentPage;
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { token: this.token, module: this.module, role: 1,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.data_table = response.data.result.result.data;
        me.data_establishments = response.data.result.establishment;
        me.data_subheadear = response.data.result.data_subheadear;


         me.rows = response.data.result.result.total;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
      me.isLoading = false;
    })
}





</script>
